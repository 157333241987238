import React from 'react';
import ReactDOM from 'react-dom';

import registerServiceWorker from './registerServiceWorker';

// Import CSS and favicon
import './App.css';

// Import containers
import App from './App';

ReactDOM.render(<App/>, document.getElementById('root'));
registerServiceWorker();
