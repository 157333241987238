import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { default as UIAppBar } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const styles = theme => ({
  headerTitle: {
    'text-align': 'center'
  },
  titleCenter: {
    'text-align': 'center',
  },
  titleRight: {
    textAlign: 'right'
  }
});

function AppBar(props) {
  const { classes, status } = props;

  const renderMiddleText = () => {
    return (
      <Typography
        style={{ 'flex': 1, width: 'auto' }}
        className={classes.titleCenter}
        variant="h5"
        color="inherit"
      >
      Číselník
      </Typography>
    );

  }

  return(
    <UIAppBar>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.toggleDrawer}
        >
          <MenuIcon />
        </IconButton>

        {renderMiddleText()}
      </Toolbar>
    </UIAppBar>
  );
};

export default withStyles(styles)(AppBar);
