import React from 'react';
import { BrowserRouter, Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';

import './App.css';

import Navigation from './components/Navigation';

import { AuthService, useAuth } from './services/AuthService';

// =====================================================

const Loading = (props) => {
  if (props.error) {
    return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
  }
  else if (props.timedOut) {
    return <div>Taking a long time... <button onClick={ props.retry }>Retry</button></div>;
  }
  else if (props.pastDelay) {
    return <div>Loading...</div>;
  }
  else {
    return null;
  }
}

const Components = {
  Login: Loadable({
    loader: () => import('./containers/LoginPage'),
    loading: Loading,
    timeout: 10000, // 10 seconds
  }),
  Output: Loadable({
    loader: () => import('./containers/OutputPage'),
    loading: Loading,
    timeout: 10000, // 10 seconds
  }),
  Dialer: Loadable({
    loader: () => import('./containers/DialerPage'),
    loading: Loading,
    timeout: 10000, // 10 seconds
  }),
  Psalms: Loadable({
    loader: () => import('./containers/PsalmsPage'),
    loading: Loading,
    timeout: 10000, // 10 seconds
  })
}

function AppBody() {
  return (
    <>
      <Navigation />
      <Outlet />
    </>
  );
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RequireAuth = () => {
  const { user, check } = useAuth();
  const location = useLocation();

  if (!user || !user.accessToken || !user.refreshToken)
    return <Navigate to="/login" state={{ from: location }} />;

  return <Outlet />;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <AuthService>
          <div className="App">
            <Routes>
              <Route path="/output" element={<Components.Output />} />
              <Route path="/*" element={<AppBody />}>
                <Route element={<RequireAuth />}>
                  <Route path="" element={<Components.Dialer />} />
                </Route>
                <Route path="login" element={<Components.Login />} />
                <Route path="psalms" element={<Components.Psalms />} />
              </Route>
            </Routes>
          </div>
        </AuthService>
      </BrowserRouter>
    )
  }
}

export default App;
