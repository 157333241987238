import 'whatwg-fetch';
import { isEmpty } from 'lodash';
import { createContext, useContext, useState, useMemo } from "react";

const ACCESS_TOKEN_KEY = "at";
const REFRESH_TOKEN_KEY = "rt";

const AuthContext = createContext();

function verifyToken(accessToken, refreshToken, guest = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch('/api/user/verify', {
        method: 'post',
        body: JSON.stringify({ accessToken, refreshToken }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.debug(res);

      if (res.status === 500)
        return reject("Internal Server Error");

      const response = await res.json();

      return resolve (response.success);
    } catch(err) {
      return reject(err);
    }
  })
};

function getNewTokens(refreshToken) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch('/api/tokens/refresh', {
        method: 'post',
        body: JSON.stringify({ refreshToken: refreshToken }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.debug(res);

      if (res.status === 500)
        return reject("Internal Server Error");

      const response = await res.json();

      if (!response)
        return reject("No response");

      return resolve(response);
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  })
};

const useUserStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      console.error(err);

      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.error(err);
    }

    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export const AuthService = ({ children }) => {
  const [user, setUser] = useUserStorage("user", null);

  const login = async (userInfo) => {
    let json;

    try {
      const res = await fetch('/api/user/login', {
        method: 'post',
        body: JSON.stringify(userInfo),
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (res.status === 500)
        return { error: true, wrongCredentials: false };

      json = await res.json();
    } catch (err) {
      console.error(err);
      return { error: true, wrongCredentials: false };
    }

    if (!json)
      return { error: true, wrongCredentials: false };

    if (!json.success)
      return { error: false, wrongCredentials: true };

    if (json && json.success) {
      setUser({ username: userInfo.username, accessToken: json.accessToken, refreshToken: json.refreshToken });

      return null;
    }
  };

  const quicklogin = async (username, refreshToken) => {
    let json;

    console.debug(username, user, refreshToken);

    if (!username || !refreshToken)
      return false;


    try {
      const res = await fetch("/api/user/quicklogin", {
        method: 'post',
        body: JSON.stringify({
          username: username,
          refreshToken: refreshToken,
        }),
        headers: {
          "Content-Type": "application/json"
        }
      });
      json = await res.json();
    } catch (err) {
      console.error(err);
      return false;
    }

    if (json && json.success) {
      setUser({ username: username, accessToken: json.accessToken, refreshToken: json.refreshToken });

      return true;
    }

    return false;
  };

  const check = (retry = 1) => {
    if (!user)
      return false;

    return verifyToken(user.accessToken, user.refreshToken)
      .catch(err => {
        console.error(err);
        return false;
      })
      .then(isOk => {
        // If check failed request a new refreshToken
        if (!isOk && retry > 0) {
          refresh()
            .then(() => check(retry -1));
        }

        return isOk;
      });
  };

  const refresh = async () => {
    if (!user || !user.refreshToken)
      return null;

    let res;
    try {
      res = await getNewTokens(user.refreshToken);
    } catch (err) {
      console.error(err);
      return null;
    }

    setUser({
      ...user,
      accessToken: res.accessToken,
      refreshToken: res.refreshToken,
    });

    return { accessToken: res.accessToken, refreshToken: res.refreshToken };
  };
  
  const logout = () => {
    setUser(null);
    return true;
  };

  const value = useMemo(
    () => ({
      user,
      login,
      quicklogin,
      check,
      refresh,
      logout
    }), [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
};

export const useAuth = () => {
  return useContext(AuthContext);
}
