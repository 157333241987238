import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AppBar from './AppBar';
import Drawer from './Drawer';

import AuthService from '../services/AuthService.js';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '64px'
  },
  drawerPaper: {
    width: 250,
    height: "100%",
  },
});

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  };

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  
  handleMenuClose = () => {
   this.setState({ anchorEl: null });
  };

  render() {
    const { status } = this.props;

    return (
      <>
        <AppBar status={status} toggleDrawer={this.handleDrawerToggle} />
        <Drawer open={this.state.open} onClose={this.handleDrawerToggle} />
      </>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigation);
