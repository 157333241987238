import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { default as UIDrawer } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import DialpadIcon from '@material-ui/icons/Dialpad';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import VisibilityIcon from '@material-ui/icons/Visibility';

const styles = theme => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    textAlign: 'center',
    ...theme.mixins.toolbar
  },
});

function Drawer(props) {
  const { classes, theme } = props;

  const drawer = (
    <React.Fragment>
      <div className={classes.drawerHeader}>
        <Typography
          style={{ flex: 1 }}
          variant="h5"
          color="inherit"
        >
          Organist's Companion
        </Typography>
      </div>
      <Divider />
      <List>
        <Link to='/'>
          <ListItem button>
            <ListItemIcon>
              <DialpadIcon />
            </ListItemIcon>
            <ListItemText primary="Dialer" />
          </ListItem>
        </Link>

        <Link to='/psalms'>
          <ListItem button>
            <ListItemIcon>
              <ViewHeadlineIcon />
            </ListItemIcon>
            <ListItemText primary="Psalms" />
          </ListItem>
        </Link>
      </List>
    </React.Fragment>
  );

  return (
    <UIDrawer
      open={props.open}
      onClose={props.onClose}
      >
      {drawer}
    </UIDrawer>
  )
}

export default withStyles(styles)(Drawer);
